<mat-card>

  <mat-card-title>
    My Story Behind Letters 2 Lost Loved Ones

  </mat-card-title>
  <mat-card-content>
    <p>
      Hello and welcome to Letters 2 Lost Loved Ones.  My name is Mike and I want to share my personal story and motivation for this site.
    </p>
    <p>
      I am a professional software engineer, former paramedic, husband to a loving and supportive wife, and loving dad to 4 great kids.
    </p>
    <p>
      Recently, I tragically lost my mom to a car crash.  My world was instantly flipped upside down in just moments.

      As I usually talked to my mom more through text messaging than by phone call, I found myself longing to message her.
      I thought certainly there must be a platform for sending letters to a lost loved one, perhaps an email address
      where the message just goes into the black hole abyss of the internet somewhere.  A grief letter if you will.
      As I searched for such a thing, I came up empty and almost immediately decided I would create a platform that does just this.
    </p>
    <p>
      The next morning, I took my software engineering skills and built and deployed the first iteration.  Letters 2 Lost Loved Ones was born.
      It started very quite basic and lacked almost any styling (it may still depending on when you are reading this as
      I am actively and rapidly building the site), and it is the start of a platform I will build and maintain for the foreseeable future.
    </p>
    <p>
      My dream is that someone else, even just one other person, will use this site and it will help them to find their inner peace after a tragic loss.
    </p>
    <p>
      Letters 2 Lost Loved Ones is a simple application that allows the user to send a letter to a deceased loved one.
      This is meant to be an emotional and spiritual release and I believe the action of sending a message can help
      to provide that emotional release and promote the healing process. There is a similar process that is used where
      you write a physical letter to a lost loved one, read the letter aloud to them, then burn the letter.
      As the letter is burned you imagine the emotions you have been feeling are released and your words are carried to that person.
      I believe in the digital world we live in, that the action of sending the letter can be used in place of burning a physical letter.
      My inspiration came from <a href="https://www.thecenterforgrowth.com/tips/processing-grief-through-writing-letters" target="_blank">Processing grief through writing letters</a>
    </p>
    <p>
      In the future, I will be adding more features including the ability to log in and save letters so you can review
      and send them at a later date thus making it possible to come back to a saved letter.
      This will forever and always remain a free and publicly available resource.

      I ask anyone that sees this post to please share the URL with anyone that could possibly benefit from it. It would also be great to hear feedback, especially if this was found to help.
    </p>

  </mat-card-content>

</mat-card>
