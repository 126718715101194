<mat-card>
  <div *ngIf="isSending">
    <mat-spinner></mat-spinner>
  </div>
  <mat-card-title>
    Letters 2 Lost Loved Ones

  </mat-card-title>
  <mat-card-content>
    <div>
      <p>Inspired by <a href="https://www.thecenterforgrowth.com/tips/processing-grief-through-writing-letters" target="_blank">Processing grief through writing letters</a></p>
      <div>Note: Copy content of message before sending if you want to save it</div>
      <p>
        Following the guidance on the referenced website try:
      </p>
        <ul>
      <li>Take your time to collect your thoughts.</li>
      <li>Write your letter to a loved one.  It doesn't have to be written all at once.  Remember there is currently no save feature. If you start to write a letter and don't finish, copy the text and save it on your computer so you can come back to it later.</li>
      <li>While keeping your loved one's image in your mind, read your letter out loud with as much emotion as you can muster as if your loved one is standing right in front of you and you are speaking to them directly.</li>
      <li>Wish your loved one a final goodbye and send your message. As the message is sending, imagine the emotions you have been feeling are being released and your words are being carried to that person.</li>
    </ul>

    </div>
    <div>
      <label>To: </label>
      <input [(ngModel)]="to">
    </div>
    <div>
      <label>From: </label>
      <input [(ngModel)]="from">
    </div>
    <div>
      <textarea matInput rows="30" cols="50" [(ngModel)]="message"></textarea>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="sendMessage()">Send Message</button>
    </div>

  </mat-card-content>

</mat-card>
